import React from "react";
import { ReactComponent as IconGlass } from "assets/icons/components/search/magnifyingGlass.svg";
import { ReactComponent as IconClose } from "assets/icons/components/search/close.svg";
const Search = ({ search, setSearch }) => {
  const onSearchChange = ({ target }) => {
    setSearch(target.value);
  };
  return (
    <section>
      <h3 className="text-head-componente">Buscador</h3>
      <div className="form">
        <label className="label">
          <input
            className="input"
            type="text"
            required=""
            placeholder="Buscar..."
            id="search"
            value={search}
            onChange={onSearchChange}
          />
          <div className="fancy-bg"></div>
          <div className="search">
            <IconGlass />
          </div>
          <button
            className="close-btn"
            type="reset"
            onClick={() => setSearch("")}
          >
            <IconClose className="icon-close" />
          </button>
        </label>
      </div>
    </section>
  );
};

export default Search;
