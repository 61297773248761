import React from "react";
import Image from "assets/images/questions/Preguntas-Frecuentes.webp";
import BannerText from "common/BannerText";
import FAQ from "components/faq/Faq";
import OutlinedText from "common/OutlinedText";

const Questions = () => {
  return (
    <>
      <BannerText Image={Image}>
        <OutlinedText
          text1="Frecuentes"
          text2="Preguntas"
          color="#fff"
          bottom_text1="20%"
          left_text1="12%"
          bottom_text2="30%"
          left_text2="10%"
        />
      </BannerText>

      <div className="container-questions">
        <OutlinedText
          position={true}
          positionTop1="30%"
          left_text1="12%"
          text1="tus dudas"
          text2="Resuelve"
          color="var(--azul-gac)"
          positionTop2="0"
          bottom_text2="120vh"
          left_text2="10%"
        />
        <FAQ />
      </div>
    </>
  );
};

export default Questions;
