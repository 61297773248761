import React from "react";
import "styles/NotFound.css";
import { NavLink } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="body">
      <div className="number">404</div>
      <div className="text404">
        <span>Hmmm!</span>
        <br />
        No encontramos lo que buscabas.
      </div>
      <div className="card-text">
        ¡Ups! Parece que el enlace que estás intentando abrir no está
        disponible. No te preocupes, tenemos una solución alternativa para ti.
        Haz clic aquí para acceder al contenido que estabas buscando.
      </div>
      <NavLink to="/" className="mt-3 btn-return">
        Regresar a inicio
      </NavLink>
    </div>
  );
};

export default NotFound;
