import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import { Home } from "components";
import {
  About,
  Blog,
  Blogs,
  Certificate,
  Contact,
  Hotel,
  NotFound,
  PolicyOne,
  PolicyThree,
  PolicyTwo,
  PQRS,
  Questions,
  Sagrilaft,
  EthicalLine,
  Payments,
} from "page";
import MainLayout from "layout/MainLayout";
import MenuRobot from "common/MenuRobot";
import Index from "page/Admin/Index";
import CreateBanner from "page/Admin/CreateBanner";
import DashboardBanners from "page/Admin/DashboardBanners";
import AddValueAffiliates from "page/Admin/affiliates/AddValueAffiliates";
import ManagerContent from "page/Admin/ManagerContent";

const keyGoogle = process.env.REACT_APP_GOOGLE_ANALYTICS;

const RoutesGAC = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    ReactGA.initialize(keyGoogle);
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title: pathname,
    });

    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, [pathname]);

    return null;
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <MainLayout>
          <MenuRobot />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sagrilaft" element={<Sagrilaft />} />
            <Route path="/about" element={<About />} />
            <Route path="/pqrs" element={<PQRS />} />
            <Route path="/document/:url" element={<Certificate />} />
            <Route
              path="/politica-de-ley-de-retracto"
              element={<PolicyOne />}
            />
            <Route
              path="/politica-de-proteccion-de-menores"
              element={<PolicyTwo />}
            />
            <Route path="/bio-seguridad" element={<PolicyThree />} />
            <Route path="/contacto" element={<Contact />} />
            <Route path="/questions" element={<Questions />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/blog/:id" element={<Blog />} />
            <Route path="/:destino/:hotel" element={<Hotel />} />
            <Route path="/pagos" element={<Payments />} />
            <Route
              path="/mercadopago"
              element={<Navigate to="/pagos" replace />}
            />

            <Route path="/linea-etica" element={<EthicalLine />} />
            <Route path="*" element={<NotFound />} />

            {/* rutas gestión de contenido */}
            <Route
              path="/d77cdf4f4ab48f705fbed94d26c7ee03831add4b9cbe2a4c7c578f8cc7361215"
              element={<Index />}
            >
              <Route path="" element={<ManagerContent />} />
              <Route path="banners" element={<DashboardBanners />} />
              <Route path="create-banners" element={<CreateBanner />} />
              <Route
                path="add-value-affiliates"
                element={<AddValueAffiliates />}
              />
            </Route>
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </>
  );
};

export default RoutesGAC;
