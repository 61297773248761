import React from "react";
import { NavLink } from "react-router-dom";

const AsideBlogs = ({ filterBlogs }) => {
  return (
    <aside>
      <h2 className="text-center h2">No te pierdas</h2>
      <article className="layout-grid-blog-id ">
        {filterBlogs()
          ?.slice(0, 10)
          ?.map((blog, index) => (
            <NavLink
              key={blog.id}
              className={
                "item-blog " + ((index === 2 || index === 5) && "add-col2")
              }
              to={`/blog/${blog.id}`}
            >
              <img src={blog.img[0].img} alt={blog.title} />
              <p className="title-blog-item aside-blog">{blog.title}</p>
            </NavLink>
          ))}
      </article>
    </aside>
  );
};

export default AsideBlogs;
