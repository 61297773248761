import { useState } from "react";

const useReactHookForm = (initialState) => {
  const [data, setData] = useState(initialState);

  const updateData = (newData) => {
    setData((prevData) => ({ ...prevData, ...newData }));
  };

  return [data, updateData];
};

export default useReactHookForm;
