import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import hoteles from "assets/HotelesNacionales.json";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "styles/hotel.css";
import useSendEventGA from "../hooks/useSendEventGA";
import OutlinedText from "common/OutlinedText";
import GridCustom from "components/Hotels/GridCustom";
import { ReactComponent as ArrowIcon } from "assets/icons/left-arrow.svg";

SwiperCore.use([Navigation]);

const Hotel = () => {
  const { destino, hotel } = useParams();
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showHotelInfo, setShowHotelInfo] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const selected = hoteles.HotelesNacionales.filter(
      (h) => h.Destino === destino && h.Hotel === hotel
    );
    setSelectedHotel(selected[0]);
  }, [destino, hotel]);

  const SendEvent = (e) => {
    e.preventDefault();
    window.open("https://wa.link/9wpb4o", "_blank");
    useSendEventGA("View Hotel", "Click en Reserva");
  };

  return (
    <>
      {selectedHotel && (
        <>
          <div className="contenedor-relative">
            <Swiper
              modules={[Autoplay]}
              navigation
              slidesPerView={1}
              spaceBetween={0}
              loop={true}
              direction="horizontal"
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
            >
              {selectedHotel.slider?.map((s, index) => (
                <SwiperSlide className="" key={index}>
                  <img
                    loading="lazy"
                    src={s.img}
                    alt={s.img}
                    key={index}
                    className=""
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {/* <h1 className="title-section" id="ask">
                {selectedHotel.Hotel}
              </h1> */}
          </div>
          <img
            loading="lazy"
            src="/images/gps-location.svg"
            alt="tag map"
            id="tag"
            width={30}
          />
          <p className="destino">{selectedHotel.Destino}</p>
          <div className="container-hotel">
            <NavLink className="text-back" onClick={() => navigate(-1)}>
              <ArrowIcon />
              Volver a destinos
            </NavLink>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <OutlinedText
                  position={true}
                  text1={selectedHotel.Hotel}
                  text2={selectedHotel.Hotel}
                  positionTop1="30px"
                  left_text1="3%"
                  color="var(--azul-gac)"
                  positionTop2="0px"
                  left_text2="0%"
                />
                <p className="justify" id="descripcion">
                  {selectedHotel.descripcion}
                </p>
                <h2 className="title-policy">CARACTERISTICAS</h2>
                <section className="icons">
                  {selectedHotel.iconos_servicios?.map((icon, index) => (
                    <img
                      loading="lazy"
                      src={icon.img}
                      alt={icon.img}
                      key={index}
                    />
                  ))}
                </section>
                {/* <button className="cta-hotel" onClick={SendEvent}>
                    <p>Reserva</p>
                    <p>{selectedHotel.noches ? "Y redime tus noches" : ""}</p>
                  </button> */}
                {/* 
                  <div className="container-info">
                    <section className="detalles">
                      <h4 className="title-policy fw-bold text-center">
                        DETALLES
                      </h4>
                      {selectedHotel.detalles?.map((detalle, index) => (
                        <div key={index}>
                          {Object.entries(detalle).map(([key, value]) => (
                            <p key={key} className="m-2">
                              <strong>{key}:</strong> {value}
                            </p>
                          ))}
                        </div>
                      ))}
                    </section>
                    <section className="alrededores">
                      <h4 className="title-policy fw-bold text-center">
                        ALREDEDORES
                      </h4>
                      {selectedHotel.alrededores?.map((icon, index) => (
                        <img
                          loading="lazy"
                          src={icon.img}
                          alt={icon.img}
                          key={index}
                          width={50}
                        />
                      ))}
                    </section>
                  </div> */}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                    <section className="habitaciones">
                      <h4 className="title-policy fw-bold text-center">
                        Habitaciones
                      </h4>
                      <Swiper
                        modules={[Navigation, Autoplay, Pagination, A11y]}
                        navigation
                        slidesPerView={1}
                        spaceBetween={0}
                        loop={true}
                        direction="horizontal"
                        pagination={{ clickable: true }}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                      >
                        {selectedHotel.habitaciones?.map((s, index) => (
                          <SwiperSlide className="" key={index}>
                            <img
                              loading="lazy"
                              src={s.img}
                              alt={s.img}
                              key={index}
                              className="custom-img"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </section>
                  </div>
                  {/* <div className="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                      <section className="map">
                        <iframe
                          src={selectedHotel.map}
                          width="520"
                          height="300"
                          title={selectedHotel.map}
                          style={{
                            border: 0,
                            borderRadius: ".5rem",
                          }}
                          allowFullScreen
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </section>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <GridCustom title="Galeria" images={selectedHotel} />

          <section className="container-info-hotel">
            <div className="tabs">
              <span
                className={`tab ${!showHotelInfo && "active"}`}
                onClick={() => setShowHotelInfo(!showHotelInfo)}
              >
                Sitios de interes
              </span>
              <span
                className={`tab ${showHotelInfo && "active"}`}
                onClick={() => setShowHotelInfo(!showHotelInfo)}
              >
                Información del hotel
              </span>
            </div>
            {showHotelInfo ? (
              <>
                <h4 className="title-policy">Información general</h4>
                <section className="info-grid">
                  {selectedHotel?.detalles?.map((detalle, index) => (
                    <div className="info-box" key={index}>
                      {Object.entries(detalle).map(([key, value]) => (
                        <div key={key}>
                          <div className="info-title">{key}</div>
                          <div className="info-content">{value}</div>
                        </div>
                      ))}
                    </div>
                  ))}
                </section>

                <h4 className="title-policy">Horarios</h4>
                <section className="info-grid">
                  <div className="info-box">
                    <div>
                      <div className="info-title">check in / check out</div>
                      <div className="info-content">
                        {selectedHotel?.horarios[0]?.check_in} -
                        {selectedHotel?.horarios[0]?.check_out}
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : (
              <section className="container-map">
                <iframe
                  src={selectedHotel.map}
                  width="100%"
                  height="400px"
                  title={selectedHotel.map}
                  style={{
                    border: 0,
                    borderRadius: ".5rem",
                  }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="zonas-interes">
                  <p className="title-zonas">Sitios de interes</p>
                  <p
                    dangerouslySetInnerHTML={{ __html: selectedHotel?.zonas }}
                  ></p>
                </div>
              </section>
            )}
          </section>
        </>
      )}
    </>
  );
};

export default Hotel;
