import React from "react";

const OutlinedText = ({
  text1,
  text2,
  color,
  positionTop1,
  bottom_text1,
  left_text1,
  positionTop2,
  bottom_text2,
  left_text2,
  position = false,
}) => {
  if (!position) {
    return (
      <>
        <div
          className="text-outlined"
          style={{
            "--positionTop": `${positionTop1}`,
            "--positionBottom": `${bottom_text1}`,
            "--positionLeft": `${left_text1}`,
          }}
        >
          {text1}
        </div>
        <div
          className="text-filled"
          style={{
            "--textColor": `${color}`,
            "--positionTop": `${positionTop2}`,
            "--positionBottom": `${bottom_text2}`,
            "--positionLeft": `${left_text2}`,
          }}
        >
          {text2}
        </div>
      </>
    );
  }

  return (
    <section className={`${position && "add-relative"} `}>
      <div
        className="text-outlined"
        style={{
          "--positionTop": `${positionTop1}`,
          "--positionBottom": `${bottom_text1}`,
          "--positionLeft": `${left_text1}`,
        }}
      >
        {text1}
      </div>
      <div
        className="text-filled"
        style={{
          "--textColor": `${color}`,
          "--positionTop": `${positionTop2}`,
          "--positionBottom": `${bottom_text2}`,
          "--positionLeft": `${left_text2}`,
        }}
      >
        {text2}
      </div>
    </section>
  );
};

export default OutlinedText;
