import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import useSendEventGA from "hooks/useSendEventGA";
import "styles/navbar.css";

const Navbar = ({ isScrolled }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const SendEvent = (e) => {
    e.preventDefault();
    window.open("https://vip.grupoalianzacolombia.com", "_blank");
    useSendEventGA("Priority", "Click en boton mi cuenta (Navbar)");
  };

  return (
    <nav className={`line-BT header ${isScrolled && "sticky1 scrolled"}`}>
      <NavLink to="/">
        <img
          loading="lazy"
          src="/images/logo/logo.webp"
          alt="Grupo Alianza Colombia Viajar"
          width={200}
          className={`${isScrolled ? "logo1" : "not-show"}`}
        />
      </NavLink>
      <button className="menu-toggle" onClick={toggleMenu}>
        &#9776;
      </button>
      <ul className={menuOpen ? "nav-open" : ""}>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Inicio
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Nosotros
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/blog"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Blog
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contacto"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Contáctanos
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/questions"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Preguntas frecuentes
          </NavLink>
        </li>
        <li>
          <button className="btn-primary" onClick={SendEvent}>
            Afiliado
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
