import React, { useState } from "react";
import Slider from "./Slider";
import items from "assets/categories.json";
import OutlinedText from "common/OutlinedText";
import { ReactComponent as ArrowGAC } from "assets/icons/arrow-gac.svg";
import "styles/slider3d.css";

const Slider3D = () => {
  const [active, setActive] = useState(0);
  // Check if the screen width is less than 768px (mobile breakpoint)
  const isMobile = window.innerWidth < 768;

  const getItemStyles = (index) => {
    const totalItems = items.length;
    const stt = (index - active + totalItems) % totalItems;

    let transformValue = "";
    let zIndexValue = 0;
    let opacityValue = 1;

    // Mobile responsive adjustments
    if (isMobile) {
      switch (stt) {
        case 0:
          transformValue = `translateX(-50%) scale(0.65) perspective(26px) rotateY(2deg)`; // Partially visible on the left
          zIndexValue = -2;
          opacityValue = 0.7;
          break;
        case 1:
          transformValue = `translateX(0) scale(1)`; // Centered and fully visible
          zIndexValue = 1;
          opacityValue = 1;
          break;
        case 2:
          transformValue = `translateX(50%) scale(0.65) perspective(26px) rotateY(-2deg)`; // Partially visible on the right
          zIndexValue = -2;
          opacityValue = 0.7;
          break;
        default:
          transformValue = `translateX(0) scale(0.7)`;
          opacityValue = 0;
          zIndexValue = -3;
          break;
      }
    } else {
      // Desktop styles
      switch (stt) {
        case 0:
          transformValue = `translateX(-300px) scale(0.9) perspective(16px) rotateY(1deg)`;
          zIndexValue = -2;
          break;
        case 1:
          transformValue = `scale(1.18)`;
          zIndexValue = 1;
          opacityValue = 1;
          break;
        case 2:
          transformValue = `translateX(300px) scale(0.9) perspective(16px) rotateY(-1deg)`;
          zIndexValue = -2;
          break;
        case 3:
          transformValue = `translateX(600px) scale(0.8) perspective(16px) rotateY(-1deg)`;
          zIndexValue = -1;
          break;
        case 4:
          transformValue = `translateX(-600px) scale(0.8) perspective(16px) rotateY(1deg)`;
          zIndexValue = -1;
          break;
        default:
          transformValue = `translateX(480px) scale(0.7) perspective(16px) rotateY(-1deg)`;
          opacityValue = 0;
          zIndexValue = -3;
          break;
      }
    }

    return {
      transform: transformValue,
      zIndex: zIndexValue,
      filter: stt === 1 ? "none" : "blur(0px)",
      opacity: opacityValue,
      backgroundImage: `url(${items[index].img})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };
  };

  // Función para dividir los hoteles en grupos de 5
  const chunkArray = (array, chunkSize) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  const activeCategoryHotels = items[(active + 1) % items.length]?.list || [];

  // Dividir hoteles en grupos de 5
  const groupedHotels = chunkArray(activeCategoryHotels, isMobile ? 1 : 5);

  const print = () => {
    return groupedHotels.map(({ Hotel }) => <div>console.log(Hotel)</div>);
  };
  print();

  const nextSlide = () => {
    setActive((prev) => (prev + 1) % items.length);
  };

  const prevSlide = () => {
    setActive((prev) => (prev - 1 + items.length) % items.length);
  };

  return (
    <section className="slider3d-container">
      <OutlinedText
        position={true}
        positionTop1="10%"
        left_text1="15%"
        text1="Categorias"
        text2="Categorias"
        color="#fff"
        positionTop2="0"
        left_text2="5%"
      />
      <div className="slider3d">
        {items.map((item, index) => {
          const isActive = (index - active + items.length) % items.length === 1;
          return (
            <div
              key={item.id}
              className={`item3d ${isActive ? "active3d" : ""}`}
              style={getItemStyles(index)}
            >
              <h3 className={`title-card-3d ${isActive ? "active" : ""}`}>
                {item.title}
              </h3>
              {isActive && <p className="text-content">{item.content}</p>}
            </div>
          );
        })}
        <ArrowGAC className="slider3d-next" onClick={nextSlide} />
        <ArrowGAC className="slider3d-prev" onClick={prevSlide} />
      </div>
      {/* Mostrar los detalles del item activo */}
      <article className="container-items-active">
        <Slider
          showIndicators={true}
          showButtons={true}
          arrowColor="var(--amarillo-gac)"
          positionIndicators={` ${isMobile ? "0%" : "-30%"}`}
        >
          {groupedHotels.map((group, index) => (
            <div key={index} className="slide-group">
              {group.map((hotel, idx) => (
                <div className="container-row-hotels" key={idx}>
                  <img
                    className="image-item-active"
                    src={hotel.Image}
                    alt={`Hotel ${idx + 1}`}
                  />
                  <h6 className="name-hotel-active">{hotel.Hotel}</h6>
                </div>
              ))}
            </div>
          ))}
        </Slider>
      </article>
    </section>
  );
};

export default Slider3D;
