import React from "react";

const PolicyTwo = () => {
  return (
    <div className="ContentEthicalLine">
      <h1 className="title-policy text-center">
        POLÍTICA DE PROTECCIÓN DE MENORES
      </h1>
      <p className="text-justify">
        De acuerdo con la Ley 679 del 3 de Agosto de 2001 expedida por El
        Congreso de la República y Ley 1336 de 2009, mediante la cual se dictan
        disposiciones para prevenir y contrarrestar la explotación, la
        pornografía y el turismo sexual con menores de edad. De conformidad con
        lo establecido en dichas leyes, las personas deben prevenir, bloquear,
        combatir y denunciar la explotación, alojamiento, uso, publicación,
        difusión de imágenes, textos, documentos, archivos audiovisuales, uso
        indebido de redes globales de información o el establecimiento de
        vínculos telemáticos de cualquier clase relacionados con material
        pornográfico o alusivo a actividades sexuales de menores de edad. En
        desarrollo de lo dispuesto en el artículo 17 de la Ley 679 de 2001,
        Grupo Alianza Colombia advierte al turista que la explotación y el abuso
        sexual de menores de edad en el país son considerados como delitos y por
        tanto sancionados penal y administrativamente, conforme con las leyes
        vigentes. En virtud de esto Grupo Alianza Colombia ha adoptado un modelo
        que promueve políticas de prevención y evita la utilización y
        explotación sexual de niños, niñas y adolescentes en la actividad
        turística.
      </p>
    </div>
  );
};

export default PolicyTwo;
