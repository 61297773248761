import React from "react";
import { ReactComponent as Facebook } from "assets/icons/facebook.svg";
import { ReactComponent as Instagram } from "assets/icons/instagram.svg";
import { ReactComponent as TikTok } from "assets/icons/tik-tok.svg";
import { ReactComponent as Youtube } from "assets/icons/youtube.svg";
import ImageFacebook from "assets/images/questions/Promos_facebook.png";
import ImageWhatsapp from "assets/images/questions/canal_Whatsapp.png";
import FormContact from "components/contact/FormContact";
import ChannelsAttention from "./ChannelsAttention";

const CareChannels = () => {
  return (
    <section className="container-contact-section">
      <FormContact />
      <article>
        <ChannelsAttention />

        <div className="container-redes">
          <h3 className="title-article">
            Redes
            <br />
            Sociales
          </h3>
          <div>
            <a
              href="https://www.facebook.com/groups/2414621858928311"
              target="_blank"
              rel="noreferrer"
              className="round-gray"
            >
              <img
                src={ImageFacebook}
                alt="Promociones Grupo Alianza Colombia"
              />
            </a>
            <a
              href="https://www.whatsapp.com/channel/0029Va1Rycc0bIdpTK5fUJ03"
              target="_blank"
              rel="noreferrer"
              className="round-gray"
            >
              <img src={ImageWhatsapp} alt="Canal Whatsapp" />
            </a>
            <a
              href="https://www.facebook.com/Grupoalianzacolombia"
              target="_blank"
              rel="noreferrer"
              className="round-gray"
            >
              <Facebook className="icon redes" />
            </a>
            <a
              href="https://www.instagram.com/grupoalianzacolombia"
              target="_blank"
              rel="noreferrer"
              className="round-gray"
            >
              <Instagram className="icon redes" />
            </a>
            <a
              href="https://www.tiktok.com/@grupoalianzacolombia?lang=es"
              target="_blank"
              rel="noreferrer"
              className="round-gray"
            >
              <TikTok className="icon redes" />
            </a>
            <a
              href="https://www.youtube.com/@grupoalianzacolombia"
              target="_blank"
              rel="noreferrer"
              className="round-gray"
            >
              <Youtube className="icon redes" />
            </a>
          </div>
        </div>
      </article>
    </section>
  );
};

export default CareChannels;
