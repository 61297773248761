import React, { useEffect, useState } from "react";
import { mercadoPago } from "services/Payments";
import { paymentsWithPayu } from "services/Payments";
import { useMercadopago } from "react-sdk-mercadopago/lib";
import { ReactComponent as ArrowIcon } from "assets/icons/left-arrow.svg";
import "styles/pay/payments.css";
const apiMercadoPago = process.env.REACT_APP_API_KEY_MERCADOPAGO;

const Selectgateway = ({ setFormConfig, user, setUser }) => {
  const mercadopago = useMercadopago.v2(apiMercadoPago, { locale: "es-CO" });
  const [codMercado, setcodMercado] = useState(null);
  const [loading, setLoading] = useState({
    loading_payu: false,
    loading_mercado: false,
  });

  const Hanldle_Payu = async () => {
    setLoading({
      ...loading,
      loading_payu: true,
    });
    try {
      const response = await paymentsWithPayu({
        contrato: user?.contrato,
        nombre: user?.titular,
        correo: user?.email,
        monto: user?.monto,
      });

      setUser({ ...user, url_payU: response.data });
      window.open(response.data, "_blank");
      setFormConfig({ step: 5 });
    } catch (error) {
      console.error("Error metodo pay U", error);
    } finally {
      setLoading({
        ...loading,
        loading_payu: false,
      });
    }
  };

  const handleMercadoPago = async () => {
    setLoading({
      ...loading,
      loading_mercado: true,
    });

    try {
      const response = await mercadoPago({
        codigo: user?.codigo,
        contrato: user?.contrato,
        tipo: user?.tipo,
        valor: user?.monto,
      });
      setcodMercado(response.data);
    } catch (error) {
      console.error("Error al crear la preferencia de pago:", error);
      alert("Se ha generado un error al crear el id de mercado pago", error);
    } finally {
      setLoading({
        ...loading,
        loading_mercado: false,
      });
    }
  };

  useEffect(() => {
    const render_btn_mercado = async () => {
      if (
        mercadopago &&
        codMercado &&
        document.querySelector(".cho-container")
      ) {
        try {
          const response = mercadopago.checkout({
            preference: {
              id: codMercado,
            },
            render: {
              container: ".cho-container", // Class where the MercadoPago button is rendered
              label: "Pagar con MercadoPago",
            },
            autoOpen: true, // Automatically open MercadoPago checkout
          });
          console.log("Respuesta del método checkout:", response);

          // Handle any response status errors
          if (response.status === "failure") {
            console.error("Error al procesar el pago:", response);
            alert(
              "Hubo un problema al procesar el pago. Por favor, inténtalo de nuevo."
            );
          }
        } catch (error) {
          console.error("Error al llamar a checkout:", error);
          alert(
            "Se produjo un error al iniciar el proceso de pago. Por favor, inténtalo de nuevo."
          );
        }
      }
    };

    if (codMercado !== null) {
      render_btn_mercado();
    }
  }, [codMercado, mercadopago]);

  return (
    <div>
      <div className="cho-container" />

      <button
        className="btn-back-step"
        onClick={() => setFormConfig({ step: 3 })}
      >
        <ArrowIcon />
        Regresar
      </button>
      <section className="container-payments ">
        <button
          className="btn-payment international"
          onClick={() => Hanldle_Payu()}
        >
          {loading.loading_payu ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Cargando...
            </>
          ) : (
            <>
              <img
                src="https://www.svgrepo.com/show/408253/world-map.svg"
                alt="pago internacional GAC"
                width={30}
              />
              Pagar desde el exterior
            </>
          )}
        </button>
        <button
          className="btn-payment national"
          onClick={() => handleMercadoPago()}
        >
          {loading.loading_mercado ? (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Cargando...
            </>
          ) : (
            <>
              Pagar desde Colombia
              <img
                src="https://www.svgrepo.com/show/405455/flag-for-flag-colombia.svg"
                alt="pago colombia GAC"
                width={30}
              />
            </>
          )}
        </button>
      </section>
    </div>
  );
};

export default Selectgateway;
