import React from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as ArrowIcon } from "assets/icons/left-arrow.svg";
import "./pay.css";

const Payment = ({ mora, user, setUser, setFormConfig }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      tipo: user.tipo,
      contrato: user.contrato,
      codigo: user.codigo,
    },
  });

  // Formatear valor de dinero
  const formatterMoney = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const OnSubmit = handleSubmit(async (data) => {
    setUser({ ...user, monto: data.valor });
    setFormConfig({ step: 4 });
  });

  return (
    <main>
      <form onSubmit={OnSubmit}>
        <article
          className="text-back"
          onClick={() => setFormConfig({ step: 2 })}
        >
          <ArrowIcon />
          Volver
        </article>

        <label htmlFor="concepto">Concepto</label>
        <input
          className="inputPay"
          type="text"
          id="concepto"
          readOnly
          {...register("tipo")}
        />
        <label htmlFor="contrato">Contrato</label>
        <input
          className="inputPay"
          type="text"
          name="contrato"
          id="contrato"
          readOnly
          {...register("contrato")}
        />
        {user.tipo !== "Reservas" && (
          <>
            {user.mora === "1" && (
              <p className="text-danger">
                {`Tu plan vacacional presenta mora desde: ${mora?.fecha_mora}`}
              </p>
            )}
            <div className="radio-inputs mt-4 mb-3">
              {mora?.pago_al_dia && (
                <label>
                  <input
                    className="radio-input"
                    type="radio"
                    name="engine"
                    onClick={() => setValue("valor", mora?.pago_al_dia || 0)}
                  />
                  <span className="radio-tile">
                    <span className="radio-label">
                      Pago para estar al día:{" "}
                      {formatterMoney.format(mora?.pago_al_dia || 0)}
                    </span>
                  </span>
                </label>
              )}
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="engine"
                  onClick={() =>
                    setValue("valor", mora?.pago_total || user.saldo)
                  }
                />
                <span className="radio-tile">
                  <span className="radio-label">
                    Pagar saldo:{" "}
                    {formatterMoney.format(mora?.pago_total || user.saldo)}
                  </span>
                </span>
              </label>
            </div>
          </>
        )}
        <div className={errors?.valor && "ErrorContainer"}>
          <label htmlFor="valor">Valor</label>
          <input
            className="inputPay"
            type="number"
            name="valor"
            id="valor"
            {...register("valor", {
              valueAsNumber: true,
              required: "Debe digitar un valor a pagar",
              min: {
                value: 1000,
                message: "El valor mínimo debe ser de 10.000",
              },
            })}
          />
          {errors?.valor && (
            <span className="errorForm">
              <i className="fa-solid fa-circle-exclamation"></i>
              {errors?.valor.message}
            </span>
          )}
        </div>

        <button disabled={isSubmitting} className="MercadoPago mt-3">
          {isSubmitting ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            "Continuar"
          )}
        </button>
      </form>
    </main>
  );
};

export default Payment;
