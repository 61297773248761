import React from "react";
import Redes from "assets/Redes.json";
import { LinkCustom } from "common";
import { NavLink } from "react-router-dom";
import "styles/Topbar.css";

const TopBar = ({ isScrolled }) => {
  return (
    <div className={`topbar ${isScrolled ? "hidden" : ""}`}>
      <NavLink to="/">
        <img
          src="/images/logo/Logo-blanco.png"
          alt="Grupo Alianza Colombia Viajar"
          width="150px"
          height="40px"
          className="logo-tob-bar"
        />
      </NavLink>

      <div className="socialIcons">
        {Redes.map(({ id, url, icon, styleClass }) => (
          <LinkCustom
            key={id}
            url={url}
            iconClass={icon}
            className={styleClass}
          />
        ))}
      </div>
    </div>
  );
};

export default TopBar;
