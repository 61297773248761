import React from "react";

const PolicyOne = () => {
  return (
    <div className="ContentEthicalLine">
      <h1 className="title-policy text-center">POLÍTICA DE LEY DE RETRACTO</h1>
      <p className="text-justify">
        Estatuto del Consumidor. Ley 1480 de 2011. “Artículo 47. Retracto. En
        todos los contratos para la venta de bienes y prestación de servicios
        mediante sistemas de financiación otorgada por el productor o proveedor,
        venta de tiempos compartidos o ventas que utilizan métodos no
        tradicionales o a distancia, que por su naturaleza no deban consumirse o
        no hayan comenzado a ejecutarse antes de cinco (5) días, se entenderá
        pactado el derecho de retracto por parte del consumidor En el evento en
        que se haga uso de la facultad de retracto, se resolverá el contrato y
        se deberá reintegrar el dinero que el consumidor hubiese pagado.
      </p>
      <p>
        El consumidor deberá devolver el producto al productor o proveedor por
        los mismos medios y en las mismas condiciones en que lo recibió. Los
        costos de transporte y los demás que conlleve la devolución del bien
        serán cubiertos por el consumidor.
      </p>
      <p>
        El término máximo para ejercer el derecho de retracto será de cinco (5)
        días hábiles contados a partir de la entrega del bien o de la
        celebración del contrato en caso de la prestación de servicios.
      </p>
      <p>Se exceptúan del derecho de retracto, los siguientes casos:</p>
      <p>
        En los contratos de prestación de servicios cuya prestación haya
        comenzado con el acuerdo del consumidor; En los contratos de suministro
        de bienes o servicios cuyo precio esté sujeto a fluctuaciones de
        coeficientes del mercado financiero que el productor no pueda controlar;
        En los contratos de suministro de bienes confeccionados conforme a las
        especificaciones del consumidor o claramente personalizados; En los
        contratos de suministro de bienes que, por su naturaleza, no puedan ser
        devueltos o puedan deteriorarse o caducar con rapidez; En los contratos
        de servicios de apuestas y loterías; En los contratos de adquisición de
        bienes perecederos; En los contratos de adquisición de bienes de uso
        personal.
      </p>
      <p>
        El proveedor deberá devolverle en dinero al consumidor todas las sumas
        pagadas sin que proceda a hacer descuentos o retenciones por concepto
        alguno. En todo caso la devolución del dinero al consumidor no podrá
        exceder de treinta (30) días calendario desde el momento en que ejerció
        el derecho”.
      </p>
    </div>
  );
};

export default PolicyOne;
