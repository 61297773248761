import React, { useEffect, useState } from "react";
import { embedCodes } from "assets/InstagramCode";
import "styles/InstagramVideos.css";

const InstagramVideo = ({ embedCode }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement("script");
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [scriptLoaded]);

  // Use a ref to only execute once after the script is loaded
  useEffect(() => {
    if (scriptLoaded) {
      window.instgrm.Embeds.process();
    }
  }, [scriptLoaded]);

  return (
    <div className="videos-container">
      {embedCodes.map((item) => (
        <div
          key={item.id}
          className="video-wrapper"
          dangerouslySetInnerHTML={{ __html: item.embedCode }}
        />
      ))}
    </div>
  );
};

export default InstagramVideo;
