import React, { useEffect, useState } from "react";
import Slider from "./video-slider/Slider";
import Slider3D from "./video-slider/Slider3D";
import GridHotels from "./Hotels/GridHotels";
import Testimonials from "common/Testimonials";
import ResponsiveImage from "common/ResponsiveImage";
import { getBannerGac } from "services/banners";
// import MembershipCard from "./membership/MembershipCard";
import "styles/Home.css";

const Home = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const getBanners = async () => {
      const response = await getBannerGac();
      setBanners(response.data);
    };

    getBanners();
  }, []);

  return (
    <>
      <section className="add-margin">
        <Slider showIndicators={true} showButtons={true}>
          {banners.map(
            ({ img_mobile, img_tablet, img_pc, img_large, link }) => (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="slide-content"
                key={img_mobile}
              >
                <ResponsiveImage
                  mobil={img_mobile}
                  tablet={img_tablet}
                  pc={img_pc}
                  large={img_large}
                />
              </a>
            )
          )}
        </Slider>
      </section>
      <Slider3D />
      {/* <MembershipCard /> */}
      <GridHotels />
      <Testimonials />
    </>
  );
};

export default Home;
