import React from "react";
import toast from "react-hot-toast";
import { ReactComponent as User } from "assets/icons/user.svg";
import { useForm } from "react-hook-form";
import { serviceToSubscribe } from "services/Contact.service";
import "styles/to-subscribe.css";

const ToSubscribe = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const OnSubmit = handleSubmit(async (datos) => {
    try {
      await serviceToSubscribe(datos);
      toast.success("suscripcion registrada exitosamente");
      reset();
    } catch (error) {
      console.error("ERROR", error);
      toast.success("Ocurrio un error, intentelo de nuevo");
    }
  });

  return (
    <section className="container-subscribe ">
      <p className="text-cta">
        Recibe actualizaciones y novedades en cualquier lugar.
      </p>
      <article className="box-subscribe">
        <div className="parallelogram"></div>
        <div className="parallelogram back"></div>
        <h6 className="text-subscribe">Suscribirse</h6>
        <form className="content-form">
          <User className="content-form icon" />
          <input
            type="email"
            placeholder="Correo Electrónico"
            className="input-subscribe"
            {...register("correo", {
              required: "El correo es obligatorio",
            })}
          />
          {errors.correo && (
            <span className="text-danger">{errors.correo.message}</span>
          )}
        </form>

        <button
          className="btn-to-subscribe"
          onClick={OnSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            "Suscribirse"
          )}
        </button>
      </article>
    </section>
  );
};

export default ToSubscribe;
