import React from "react";
import { ReactComponent as ArrowIcon } from "assets/icons/left-arrow.svg";

const RenderBtnPayment = ({ user, setFormConfig }) => {
  return (
    <div>
      <button
        className="btn-back-step"
        onClick={() => setFormConfig({ step: 4 })}
      >
        <ArrowIcon />
        Regresar
      </button>
      {user?.url_payU && (
        <a
          href={user?.url_payU}
          target="_blank"
          rel="noopener noreferrer"
          className="btn_payment_payu"
        >
          <img
            src="https://vip.grupoalianzacolombia.com/images/pasarela/payU.png"
            alt="pago payu priority"
          />
        </a>
      )}
    </div>
  );
};

export default RenderBtnPayment;
