import React from "react";
import TableBanners from "./TableBanners";
import { NavLink } from "react-router-dom";

const DashboardBanners = () => {
  return (
    <>
      <h1 className="centrar">Lista de banners</h1>
      <NavLink className="btn-about-contact btn-add-banner" to="create-banners">
        Crear Banner
      </NavLink>
      <TableBanners />
    </>
  );
};

export default DashboardBanners;
