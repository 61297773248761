import React from "react";
import { ReactComponent as CallCenter } from "assets/icons/call-center.svg";
import { ReactComponent as Correo } from "assets/icons/correo.svg";
import { ReactComponent as Ubicacion } from "assets/icons/ubicacion.svg";

const ChannelsAttention = ({ isFooter = false }) => {
  return (
    <div className={`contact-info ${isFooter && "footer"}`}>
      {isFooter ? (
        <h4 className="title-article">
          Canales de
          <br />
          Atención
        </h4>
      ) : (
        <h2 className="title-article">
          Canales de
          <br />
          Atención
        </h2>
      )}
      <div className="contact-item">
        <CallCenter className="icon-contact" />
        <div className={`contact-details ${isFooter && "footer"}`}>
          <a href="tel:+6015520100">(601)5520100</a>
        </div>
      </div>

      <div className="contact-item">
        <Correo className="icon-contact" />
        <div className={`contact-details ${isFooter && "footer"}`}>
          <a href="mailto:sac@grupoalianzacolombia.com">
            sac@grupoalianzacolombia.com
          </a>
          <a href="mailto:reservas@grupoalianzacolombia.com">
            reservas@grupoalianzacolombia.com
          </a>
        </div>
      </div>

      <div className="contact-item">
        <Ubicacion className="icon-contact" />
        <div className={`contact-details ${isFooter && "footer"}`}>
          <p>Oficina Principal</p>
          <p>Bogotá, Calle 93B # 17-25 Oficina 309-310-311</p>
          <p>Edificio Centro Empresarial PH</p>
        </div>
      </div>
    </div>
  );
};

export default ChannelsAttention;
