import axios from "axios";
const baseUrl = "https://grupoalianzacolombia.com/upload.php";

const uploadFileGac = async (image) => {
  try {
    const response = await axios.post(baseUrl, image);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export { uploadFileGac };
