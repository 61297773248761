import axios from "axios";

const SendFileCloudinary  = async ( file ) =>{
  const url = 'https://api.cloudinary.com/v1_1/dhtnncgka/raw/upload';
  const data = new FormData();
  data.append("file", file);
  data.append("upload_preset", "pqrs_grupoAlianza")
  data.append("cloud_name", "dhtnncgka")
  data.append("resource_type", "raw");
  const response = await axios.post(url, data)
  const UrlImage = response.data.secure_url;
  return {
    img: UrlImage,
    img_id: response.data.public_id,
    status: 200
  }
}

const SendEmail = async ( payload ) => {
  const url = 'https://priority-api-gac-production.up.railway.app/api/pqrs/mail';
  const response = await axios.post(url, payload)
  return response
}


export {
  SendFileCloudinary,
  SendEmail
}