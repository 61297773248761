import React from "react";

const Sagrilaft = () => {
  return (
    <section
      style={{
        margin: "150px auto",
        display: "grid",
      }}
      className="centrar"
    >
      <img
        src="/images/sagrilaft/SAGRILAFT_1.webp"
        alt="SAGRILAFT_1"
        onContextMenu={(e) => e.preventDefault()}
        onDragStart={(e) => e.preventDefault()}
        className="img-fluid centrar"
      />
      <img
        src="/images/sagrilaft/SAGRILAFT_2.webp"
        alt="SAGRILAFT_2"
        onContextMenu={(e) => e.preventDefault()}
        onDragStart={(e) => e.preventDefault()}
        className="img-fluid"
      />
    </section>
  );
};

export default Sagrilaft;
