export const questionList = {
  RCI: [
    {
      question: "¿Qué es RCI?",
      answer:
        "A través de los programas de intercambio de RCI, puedes tomarte las vacaciones de tus sueños y explorar el mundo a través del acceso a más de 4.300 resorts afiliados en más de 100 países.",
    },
    {
      question: "¿Cómo activo mi RCI?",
      answer:
        "<p>Para hacerlo y poder reservar las vacaciones soñadas para ti y tu familia, solo debes solicitar la activación de tu código a través de nuestro correo electrónico <a href='mailto:sac@grupoalianzacolombia.com'>sac@grupoalianzacolombia.com</a> Luego de estar activado el RCI, recibirás a vuelta de correo el siguiente documento para que puedas ingresar <a href='/files/INSTRUCTIVODEREGISTRORCI.pdf' target='_blank' rel='noopener noreferrer'><u>Ver documento</u></a> Adicionalmente, se te enviará un documento de bienvenida junto con tu código. <a href='/files/RCI.pdf' target='_blank' rel='noopener noreferrer'><u>Ver ejemplo</u></a></p>",
    },
    {
      question: "¿Cómo uso mi código RCI?",
      answer:
        "<p>Recuerda que con RCI puedes consumir noches en destinos mágicos, para ello, solo debes solicitar tu depósito* enviando un correo electrónico a <a href='mailto:sac@grupoalianzacolombia.com'>sac@grupoalianzacolombia.com</a> </p> <b>*Corresponde al tiempo en semanas para hacer uso de noches.</b>",
    },
    {
      question: "¿Dónde puedo usar el RCI?",
      answer:
        "<p>Con tu RCI puedes conocer varias playas hermosas del mundo y las más visitadas por los turistas. Recuerda que con este código no podrás viajar a Cuba ni a Rusia.</p> <b>*No aplica para ciudades capitales sin playa</b>",
    },
    {
      question: "¿Mi reserva con RCI tiene costo?",
      answer:
        "Sí, debes pagar la tasa de intercambio y este se realiza en dólares (USD) de acuerdo con la TRM del día en que realices el pago. ",
    },
    {
      question: "¿Existe una diferencia entre RCI y My Week Vacation?",
      answer:
        "Sí, RCI se le brinda a quienes se afilien a la membresía por dos (2) años y My Week Vacation es un certificado para 1 semana de alojamiento",
    },
  ],
  membresía: [
    {
      question: "Servicios y beneficios de tu membresía",
      answer:
        "<p>La membresía es una inscripción que te permite adquirir el número de noches de alojamiento de acuerdo al contrato y durante su vigencia, en los siguientes hoteles: <br> </p><ul><li>Hotel Wayira Beach.</li><li>Hotel Amazon</li><li>Hoteles comercializados y operados por la cadena On Vacation en San Andrés Islas.</li></ul> <p>Para hacer uso de las noches de alojamiento, es necesario que hayas cumplido con las condiciones de pago de la membresía y el pago de los servicios especiales, si los hoteles en los que se va a alojar operan de esa forma.</p><br> <p>Estos servicios están compuestos por alimentación en desayuno, almuerzo y cena en el restaurante del hotel, toallas, derecho a consumir las bebidas que solicites en el bar durante el horario de operación y acceso a zonas exclusivas del hotel o preferenciales habilitadas especialmente para brindarte una mejor experiencia de servicio. </p> <br><p> La prestación del servicio hotelero estará sujeto a la confirmación de la reserva según disponibilidad. De igual manera, se te informará el portafolio de hoteles, donde se relacionan aquellos que no requieren el pago de los servicios especiales para fijar la reserva de alojamiento. </p> <p>Estos hoteles podrán ser consultados en nuestra página web <a href='/'> www.grupoalianzacolombia.com. </a>Es posible que haya cambios en los hoteles a los que  se tenga acceso con la membresía; sin embargo, los nuevos tendrán la misma calidad y categoría por lo que no implica una desmejora en los términos del contrato. </p> <br> <p>Adicionalmente, podrás tener eventualmente acceso a precios especiales en productos como arrendamiento de vehículos, tours, tiquetes aéreos, entre otros.</p> <br><b>*Para más información, consulta las cláusulas de tu contrato.</b>  ",
    },
    {
      question: "¿Cómo usar las noches de alojamiento de mi membresía?",
      answer:
        "<p>Con tu membresía puedes contar con un número de noches de alojamiento máximas durante la vigencia de la misma, que corresponde a tomar el número de noches por año y multiplicarlas por el número de años de vigencia. Las noches a las que tienes derecho, se descontarán de tu membresía por cada servicio de alojamiento que se preste a cada huésped de las reservas que realices. </p> <br><p>Ejemplo:</p> </p>Si fijas una reserva en la que hay dos (2) huéspedes por un ciclo de alojamiento de cuatro noches, se descontarán de tu membresía ocho (8) noches de alojamiento. </p> <br> <p>Cuando hagas uso de todas las noches antes de completar la vigencia de la membresía, tendrás derecho a recibir hasta un 20% de descuento sobre todas las reservas que solicites, hasta culminar la duración del contrato. </p>    ",
    },
    {
      question: "Paso a paso para ingresar a Alianza Priority",
      answer:
        "<p>Alianza Priority es un portal diseñado para que nuestros afiliados gestionen sus reservas las 24 horas de los siete días de la semana, además, de encontrar tarifas especiales en tours, hoteles, vuelos y traslados. <a href='/files/alianza_priority.pdf' target='_blank' rel='noopener noreferrer'><u>Aquí</u></a> podrás encontrar el documento explicando cómo debes ingresar a nuestra plataforma Alianza Priority y poder realizar tu reserva a destinos nacionales e internacionales con descuentos exclusivos. </p>",
    },
    {
      question: "¿Por qué me llega una factura a mi correo?",
      answer:
        "<p>Luego de adquirir tu membresía y para dar cumplimiento a la ley, debemos enviarte la factura a tu correo electrónico en un periodo de diez (10) días calendario.</p><p><br>Recuerda, esta factura significa que ya está paga y únicamente se evidenciará el valor por los derechos de afiliación.</p><br> <img src='/files/FacturaEjemplo.png' alt='FacturaEjemplo' />",
    },
  ],
};
