import { apiConfig } from "./apiConfig";

const saveBanner = async (payload) => {
  return await apiConfig.post("/banners/create", payload);
};

const getBannerGac = async () => {
  return await apiConfig.get("/banners");
};

const deleteBanner = async (id) => {
  return await apiConfig.delete(`banners/delete/${id}`);
};
const updateBanner = async (id, payload) => {
  return await apiConfig.patch(`banners/update/${id}`, payload);
};

export { saveBanner, getBannerGac, deleteBanner, updateBanner };
