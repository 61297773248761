import React from "react";
import CategoriesBlogs from "assets/CategoriesBlogs.json";

const BlogIndex = ({ activeCategory, handleCategory }) => {
  return (
    <section className="container-index-blog">
      <span className="fading-border"></span>
      <h3 className="text-head-componente">Indice</h3>
      {CategoriesBlogs.map(({ category, subcategory }, index) => (
        <div
          key={index}
          className={`item-category ${activeCategory === category && "active"}`}
          onClick={() => handleCategory(category, subcategory)}
        >
          <p>{category}</p>
          <span className="value-category">
            {subcategory.split(",").length}
          </span>
        </div>
      ))}
      <span className="fading-border"></span>
    </section>
  );
};

export default BlogIndex;
