import React from "react";
import { NavLink } from "react-router-dom";

const CardOption = ({ titulo = "", descripcion = "", onCrear, route = "" }) => {
  return (
    <NavLink to={route} className="card-container">
      <h3>{titulo}</h3>

      <div className="image-container">
        <img
          src="https://accupay-react.vercel.app/images/whyAccoupayCard_5.png"
          alt="Visa Support"
        />
      </div>
      <h4 className="card-title">{descripcion}</h4>
    </NavLink>
  );
};

export default CardOption;
