import React from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { postCreateContact } from "services/Contact.service";

const FormContact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const OnSubmit = handleSubmit(async (datos) => {
    try {
      await postCreateContact(datos);
      toast.success("Formulario enviado correctamente");
      reset();
    } catch (error) {
      console.error("ERROR", error);
      toast.success("Ocurrio un error, intentelo de nuevo");
    }
  });

  return (
    <form onSubmit={OnSubmit} className="form-contact">
      <div>
        <label htmlFor="names">Nombres y Apellidos</label>
        <input
          id="names"
          {...register("nombre", {
            required: "El campo Nombre es obligatorio",
          })}
          className={`form-field ${errors.nombre && "invalid"}`}
        />

        {errors.nombre && (
          <span className="mt-5 text-danger">{errors.nombre.message}</span>
        )}
      </div>
      <div className="row-contact">
        <div className="form-group">
          <label htmlFor="country">País</label>
          <input
            id="country"
            {...register("pais", {
              required: "El campo País es obligatorio",
            })}
            className={`form-field ${errors.pais && "invalid"}`}
          />
          {errors.pais && (
            <span className="mt-5 text-danger">{errors.pais.message}</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="place">Ciudad</label>
          <input
            id="place"
            {...register("ciudad", {
              required: "El campo Ciudad es obligatorio",
            })}
            className={`form-field ${errors.ciudad && "invalid"}`}
          />
          {errors.ciudad && (
            <span className="mt-5 text-danger">{errors.ciudad.message}</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="tel">Teléfono</label>
          <input
            id="tel"
            {...register("telefono", {
              required: "El campo teléfono es obligatorio",
            })}
            className={`form-field ${errors.telefono && "invalid"}`}
          />
          {errors.telefono && (
            <span className="mt-5 text-danger">{errors.telefono.message}</span>
          )}
        </div>
      </div>
      <div className="row-contact">
        <div className="form-group">
          <label htmlFor="email">Correo electrónico</label>
          <input
            id="email"
            {...register("correo", {
              required: "El campo Correo electrónico es obligatorio",
            })}
            className={`form-field ${errors.correo && "invalid"}`}
          />
          {errors.correo && (
            <span className="mt-5 text-danger">{errors.correo.message}</span>
          )}
        </div>
        <div>
          <label htmlFor="date">Número de identificación</label>
          <input
            id="date"
            {...register("cedula", {
              required: "El campo cédula es obligatorio",
            })}
            className={`form-field ${errors.cedula && "invalid"}`}
          />
          {errors.cedula && (
            <span className="mt-5 text-danger">{errors.cedula.message}</span>
          )}
        </div>
      </div>
      <div>
        <label htmlFor="msj">Mensaje</label>
        <textarea
          id="msj"
          className={`form-field ${errors.mensaje && "invalid"}`}
          rows={5}
          {...register("mensaje", {
            required: "El campo Mensaje es obligatorio",
          })}
        />
        {errors.mensaje && (
          <span className="mt-5 text-danger">{errors.mensaje.message}</span>
        )}
      </div>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="accept-terms"
          {...register("acceptTerms", {
            required:
              "Debes aceptar los terminos y condiciones para poder continuar",
          })}
        />
        <label htmlFor="accept-terms">
          Con el diligenciamiento de mis datos en el presente formulario acepto
          de forma previa, expresa e informada la Autorización para el
          tratamiento de datos personales. ver Tratamiento de datos personales
        </label>
      </div>
      {errors.acceptTerms && (
        <span className="mt-5 text-danger">{errors.acceptTerms.message}</span>
      )}

      <button className="btn-contact" disabled={isSubmitting}>
        {isSubmitting ? (
          <span className="spinner-border spinner-border-sm"></span>
        ) : (
          "Enviar"
        )}
      </button>
    </form>
  );
};

export default FormContact;
