import React from "react";
import CardOption from "./components/common/CardOption";
import "styles/dashboardAdmin/manager-content.css";

const ManagerContent = () => {
  const crearElemento = (tipo) => {
    console.log(`Creando un nuevo ${tipo}`);
  };
  return (
    <>
      <h2 className="title-policy text-center">Menú de Administración</h2>
      <div className="menu-admin">
        <CardOption
          titulo="Crear Banner"
          descripcion="Añadir un nuevo banner visual a la página principal."
          onCrear={() => crearElemento("banner")}
          route="banners"
        />
        <CardOption
          titulo="Crear valor"
          descripcion="Añadir una nueva valor a la seccion de afiliados "
          onCrear={() => crearElemento("noticia")}
          route="add-value-affiliates"
        />
      </div>
    </>
  );
};

export default ManagerContent;
