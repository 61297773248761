import { ReactComponent as Facebook } from "assets/icons/facebook-top.svg";
import { ReactComponent as Instagram } from "assets/icons/instagram-top.svg";
import { ReactComponent as TikTok } from "assets/icons/tiktok-top.svg";
import { ReactComponent as Youtube } from "assets/icons/youtube-top.svg";

const iconsExportSvg = {
  Facebook,
  Instagram,
  TikTok,
  Youtube,
};

export default iconsExportSvg;
