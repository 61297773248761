import { apiConfig } from "./apiConfig";

const saveMark = async (payload) => {
  return await apiConfig.post("/marks/create", payload);
};

const getMarksGac = async () => {
  return await apiConfig.get("/marks");
};

const deleteMark = async (id) => {
  return await apiConfig.delete(`marks/delete/${id}`);
};
const updateMark = async (id, payload) => {
  return await apiConfig.patch(`marks/update/${id}`, payload);
};

export { saveMark, getMarksGac, deleteMark, updateMark };
