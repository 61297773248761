import axios from "axios";
const url = "https://priority-api-gac-production.up.railway.app/api";

const mercadoPago = async (payload) => {
  const response = await axios.post(`${url}/mercadopago/pagos-web`, payload);
  return response;
};

const getUserInfo = async (cedula) => {
  const { data } = await axios.post(`${url}/users/user-payment`, cedula);
  return data;
};

const getValueMora = async (codigo) => {
  const { data } = await axios.post(`${url}/users/payments-web`, {
    codigo: codigo,
  });
  return data;
};

const paymentsWithPayu = async (payload) => {
  const resp = await axios.post(
    `https://priority-api-gac-production.up.railway.app/api/payu/pagos-web`,
    payload
  );
  return resp;
};
export { mercadoPago, getUserInfo, getValueMora, paymentsWithPayu };
