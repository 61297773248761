import React from "react";
import { Outlet } from "react-router-dom";

const Index = () => {
  return (
    <main className="container-admin">
      <Outlet />;
    </main>
  );
};

export default Index;
