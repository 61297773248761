import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import blogs from "assets/Blogs.json";
import AsideBlogs from "components/AsideBlogs";
import SlideImages from "components/SlideImages";
import Search from "components/Search/Search";
import BlogIndex from "components/blog/BlogIndex";
import OutlinedText from "common/OutlinedText";
import { ReactComponent as ArrowIcon } from "assets/icons/left-arrow.svg";

const Blog = () => {
  const [Info, setInfo] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const selected = blogs.filter((b) => b.id === id);
    setInfo(selected[0]);
    console.log(selected);
  }, [id]);

  const [search, setSearch] = useState("");
  const [activeCategory, setActiveCategory] = useState(null);

  const filterBlogs = () => {
    const searchWords = search
      .split(",")
      .map((word) => word.trim().toLowerCase());
    if (searchWords.length === 0) {
      return blogs.slice();
    }
    const filtered = blogs.filter((blog) => {
      const blogKeywords = blog.keywords
        .split(",")
        .map((keyword) => keyword.trim().toLowerCase());
      const blogText = blog.text.toLowerCase();
      return searchWords.some(
        (word) =>
          blog.title.toLowerCase().includes(word) ||
          blogKeywords.includes(word) ||
          blogText.includes(word)
      );
    });

    return filtered.slice();
  };

  const handleCategory = (category, subcategory) => {
    setSearch(subcategory);
    setActiveCategory(category);
  };
  return (
    <>
      <div className="contenedor-relative">
        <img loading="lazy" src={Info?.img[1].img} alt="" width={"100%"} />
      </div>

      <div className="container p-2 mt-1 text-center">
        <NavLink className="text-back" onClick={() => navigate(-1)}>
          <ArrowIcon />
          Volver
        </NavLink>
        <div className="grid-row-blog">
          <div className="column">
            {/* <OutlinedText
              position={true}
              positionTop1="30px"
              left_text1="1%"
              text1={Info?.title}
              text2={Info?.title}
              color="var(--azul-gac)"
              positionTop2="0px"
              left_text2="0%"
            /> */}
            <h1 className="text-head-componente">{Info?.title}</h1>
            <SlideImages images={Info?.img} />
            {/* Componente de slide de imagenes */}
            <figcaption className="mt-2">
              Tomada:
              <cite>{Info?.imageAuthor ? Info?.imageAuthor : "Pixabay"}</cite>
            </figcaption>
            <p
              className="text-start mt-3"
              dangerouslySetInnerHTML={{ __html: Info?.text }}
            ></p>
          </div>
          <div className="column">
            <Search search={search} setSearch={setSearch} />
            <BlogIndex
              activeCategory={activeCategory}
              handleCategory={handleCategory}
            />
            <AsideBlogs blogs={blogs} filterBlogs={filterBlogs} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
