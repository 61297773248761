import React, { useEffect, useState } from "react";
import { Navbar, Footer, TobBar } from "common";
import CountDown from "common/CountDown";
// import NavbarOld from "common/NavbarOld";

const MainLayout = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <header>
        <TobBar isScrolled={isScrolled} />
        <Navbar isScrolled={isScrolled} />
      </header>
      <CountDown targetDate="2024-11-29T23:59:59Z" />
      {/* <NavbarOld /> */}
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;
