const { apiConfig } = require("./apiConfig");

const postCreateContact = async (payload) => {
  return await apiConfig.post("/contacts/create", payload);
};

const serviceToSubscribe = async (correo) => {
  return await apiConfig.post("/newsletter/create", correo);
};

export { postCreateContact, serviceToSubscribe };
