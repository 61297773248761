import React, { useEffect, useState } from "react";
import textAbout from "assets/about.json";
import BannerText from "common/BannerText";
import OutlinedText from "common/OutlinedText";
import useSendEventGA from "hooks/useSendEventGA";
import Slider from "components/video-slider/Slider";
import Image from "assets/images/questions/about-1.webp";
import BannerAfiliado from "assets/images/questions/Banner-Afiliado.webp";
import AboutImage from "assets/images/questions/Nosotros.webp";

import "styles/about.css";
import { getMarksGac } from "services/marks";
import AnimatedNumber from "./Admin/components/common/AnimatedNumber";

const About = () => {
  const [valuesAffiliates, setvaluesAffiliates] = useState([]);

  useEffect(() => {
    const getValuesAffiliate = async () => {
      try {
        const response = await getMarksGac();
        setvaluesAffiliates(response.data);
      } catch (error) {
        console.error("error get values:", error);
      }
    };

    getValuesAffiliate();
  }, []);

  const SendEvent = (e) => {
    e.preventDefault();
    window.location.href = "tel:+6015520100";
    useSendEventGA("Contáctanos", "Click en boton Contáctanos ");
  };

  return (
    <section>
      <BannerText Image={Image}>
        <OutlinedText
          text1="colombia"
          text2="grupo alianza"
          color="#fff"
          bottom_text1="23%"
          left_text1="8%"
          bottom_text2="30%"
          left_text2="8%"
        />
      </BannerText>
      <article>
        <div className="section-text">
          <OutlinedText
            text1="Nosotros"
            text2="Nosotros"
            color="var(--azul-gac)"
            bottom_text1="40%"
            left_text1="20%"
            bottom_text2="50%"
            left_text2="22%"
          />
        </div>
        <div className="container-about">
          <img
            className="image-about"
            src={AboutImage}
            alt="Acerca de nosotros"
          />

          <div className="container-main-text">
            <h1 className="">¿Quiénes somos?</h1>
            <h6 className="">Agencia de viajes</h6>
            <p className="line-red"></p>
            <p className="">
              Grupo Alianza Colombia es una empresa en la industria turística
              colombiana, que cuenta con operación en varias ciudades del país
              desde hace más de 4 años, ofreciendo distintos servicios en
              turismo, recreación, descanso, diversión, cultura, gastronomía,
              entre otros, con la mejor relación del costo/ beneficio.
            </p>
            <p>
              La membresía ALIANZA, está diseñada para clientes que quieren
              recibir un servicio exclusivo y descuentos especiales en tarifas.
            </p>
            <button className="btn-about-contact" onClick={SendEvent}>
              Contáctanos
            </button>
          </div>
        </div>
      </article>

      <BannerText Image={BannerAfiliado}>
        <OutlinedText
          text1="Afiliados"
          text2="Afiliados"
          color="#fff"
          positionTop1="30%"
          left_text1="10%"
          positionTop2="25%"
          left_text2="8%"
        />

        <div className="stats-banner">
          {valuesAffiliates?.map((item) => (
            <div className="stat-item" id={item.id} key={item.id}>
              <img src={item.icono} alt="icono" className="icon-item-about" />
              <div className="stat-info">
                <AnimatedNumber targetValue={item.valor} />
                <div className="stat-label">{item.titulo}</div>
              </div>
            </div>
          ))}
        </div>
      </BannerText>

      <Slider
        showAlternButtons={true}
        showIndicators={false}
        showButtons={false}
      >
        {textAbout.map(({ title, text, image }, index) => (
          <div className="vision-section" key={index}>
            <div className="vision-text">
              <OutlinedText
                text1={title}
                text2={title}
                color="#fff"
                positionTop1="9%"
                left_text1={`${
                  index === 1 ? "115%" : index === 2 ? "215%" : "15%"
                }`}
                positionTop2="5%"
              />
              <div className="description-text">
                <p dangerouslySetInnerHTML={{ __html: text }}></p>
              </div>
            </div>
            <div className="vision-image">
              <img className="vision-image" src={image} alt={title} />
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default About;
