import React from "react";
import PropTypes from "prop-types";
import iconsExportSvg from "common/IconsExport";

const LinkCustom = ({ url, iconClass, className = "" }) => {
  const IconComponent = iconsExportSvg[iconClass];
  return (
    <a href={url} target="_blank" rel="noreferrer" className={` ${className}`}>
      {IconComponent ? <IconComponent /> : null}
    </a>
  );
};

LinkCustom.propTypes = {
  url: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default LinkCustom;
