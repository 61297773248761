import React from "react";
import { useParams } from "react-router-dom";

const Certificate = () => {
  const { url } = useParams();
  return (
    <div style={{ width: "100%", height: "800px" }}>
      <iframe
        title="PDF Viewer"
        src={`/files/${encodeURIComponent(url)}`}
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </div>
  );
};

export default Certificate;
