import React, { useState, useEffect, useRef } from "react";

const AnimatedNumber = ({ targetValue }) => {
  const [currentValue, setCurrentValue] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    // Crear el observer para observar si el componente está en el viewport
    const observer = new IntersectionObserver(
      (entries) => {
        // Si la sección entra en el viewport, iniciar la animación
        if (entries[0].isIntersecting) {
          startCounting();
        } else {
          resetCounting(); // Si sale del viewport, reiniciamos
        }
      },
      {
        threshold: 0.5, // Solo considerar cuando el 50% del componente sea visible
      }
    );

    // Comenzar a observar el componente
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Limpiar el observer cuando el componente se desmonte
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const startCounting = () => {
    // Evitar que la animación se inicie más de una vez
    if (hasAnimated) return;
    setHasAnimated(true);

    const incrementStep = Math.ceil(targetValue / 30);

    const interval = setInterval(() => {
      setCurrentValue((prevValue) => {
        if (prevValue + incrementStep >= targetValue) {
          clearInterval(interval);
          return targetValue; // Detener cuando lleguemos al valor objetivo
        }
        return prevValue + incrementStep;
      });
    }, 50);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  };

  const resetCounting = () => {
    // Si el componente sale del viewport, reiniciamos el estado y la animación
    setHasAnimated(false);
    setCurrentValue(0); // Reiniciar el valor
  };

  return (
    <div ref={sectionRef}>
      <h1 className="stat-number">{currentValue.toLocaleString()}</h1>
    </div>
  );
};

export default AnimatedNumber;
